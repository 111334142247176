<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="批量退款">
      <el-form ref="refund" :model="refundParam" :rules="rules" size="mini" label-width="150px">
        <el-form-item label="线下退款方式" prop="channel">
          <el-radio-group v-model="refundParam.channel" size="mini">
            <el-radio-button v-for="(item, index) in channelOptions" :key="index" :label="item.value"
              :disabled="item.disabled">{{item.label}}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否全额退款" prop="whole">
          <el-radio-group v-model="refundParam.whole" size="medium">
            <el-radio v-for="(item, index) in wholeOptions" :key="index" :label="item.value"
              :disabled="item.disabled">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="退款金额" prop="money" v-show="refundParam.whole==0">
          <el-input-number v-model="refundParam.money" placeholder="请输入退款金额" 
          :step='1' :min="0.01" :precision="2"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="退款商品数量" prop="refundGoodsNum" v-show="refundParam.whole==0">
          <el-input-number v-model="refundParam.refundGoodsNum" placeholder="退款商品数量" :step='1' :min="1">
          </el-input-number>
        </el-form-item>
        <el-form-item label="退款原因" prop="remark">
          <el-input v-model="refundParam.remark" type="textarea" placeholder="请输入退款原因" :maxlength="100"
            :autosize="{minRows: 2, maxRows: 4}" :style="{width: '50%'}"></el-input>
        </el-form-item>
      <div style="width:300px;margin-left:150px;font-size:12px;margin-bottom:10px;color:red;line-height:20px;">
        <p>此功能只是订单退款的信息记录，没有实际的资金操作。</p>
        <p>如需操作资金退款，请自行通过线下手工处理。</p>
        </div>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  batchRefundOrder
} from "@/api/orders";

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
      refundParam: {
        query: {},
        channel: "微信",
        whole: 1,
        money: undefined,
        refundGoodsNum: 1,
        remark: undefined,
      },
      rules: {
        channel: [{
          required: true,
          message: '支付方式不能为空',
          trigger: 'change'
        }],
        whole: [{
          required: true,
          message: '是否全额退款不能为空',
          trigger: 'change'
        }],
        money: [{
          type: 'number',
          message: '请输入退款金额',
          trigger: 'blur'
        }],
        refundGoodsNum: [{
          type: 'number',
          message: '退款商品数量',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请输入退款原因',
          trigger: 'blur'
        }],
      },
      channelOptions: [{
        "label": "微信",
        "value": "微信"
      }, {
        "label": "支付宝",
        "value": "支付宝"
      }, {
        "label": "银行卡",
        "value": "银行卡"
      }],
      wholeOptions: [{
        "label": "是",
        "value": 1
      }, {
        "label": "否",
        "value": 0
      }],
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {},
  methods: {
    store(query) {
      this.refundParam.query = query
    },
    onOpen() {
    },
    onClose() {
      this.$refs['refund'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },
    success() {
      this.$emit('success')
    },
    handelConfirm() {
      const that = this
      this.$refs['refund'].validate(valid => {
        if (!valid) return
        const param = {
          ...this.refundParam
        }
        that.loading = true
        batchRefundOrder(param).then(res=>{
          console.log("res", res)
          if(res.code===200) {
            that.loading = false
            that.$message.success(res.message)
            that.success()
            that.close()
          } else {
            that.loading = false
            that.$message.error(res.message)
          }
        }).catch(err=>{
          that.loading = true
        })
      })
    },
  }
}
</script>
<style>
</style>
