<template>
  <div>
    <ul>
      <li class="btn-item" v-if="role == 'retailer' && tab == 11">
        <el-dropdown @command="resumeStateFun">
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                order.sendSupplierGroup == 0
                  ? '状态为未推送群'
                  : '状态为已推送，时间：' +
                    order.sendSupplierGroupAt
              "
              placement="top"
            >
          <el-button plain size="mini">
              {{ changeBtnText }}
            <i
              v-if="order.sendSupplierGroup == 1"
              class="el-icon-circle-check send-success-icon"
            ></i>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
            </el-tooltip>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                command="0"
                :disabled="order.sendSupplierGroup == 0"
                >置为尚未推送</el-dropdown-item
              >
              <el-dropdown-item
                command="1"
                :disabled="order.sendSupplierGroup == 1"
                >置为无需推送</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
      <li class="btn-item">
        <el-button v-if="order && order.copyContent"
          @click="copyIt"
          id="copyOrder"
          plain
          size="mini"
          :data-clipboard-text="order.copyContent"
          >复制订单</el-button
        >
      </li>

      <li class="btn-item" v-if="tab == 3 || tab == 4">
        <el-button
          @click="dontNeedDelivery"
          plain
          size="mini"
          >无需物流已发货</el-button
        >
      </li>

    </ul>
  </div>
</template>
<script>
import { changeSendStatus, markCompletedWithoutDelivery } from "@/api/orders";
import Clipboard from "clipboard";

export default {
  name: "Buttons",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      order: this.item,
      changeBtnText: "修改推送状态",
    };
  },
  watch: {
    item() {},
  },
  methods: {
    dontNeedDelivery() {
      const param = {
        query: {
          action: "SELECT",
          orderIds: [this.order.id],
          role: this.role,
        }
      };
      markCompletedWithoutDelivery(param.query).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.$emit("refresh");
        } else {
          this.$message.error(res.message);
        }
      })
    },
    resumeStateFun(state) {
      const param = {
        query: {
          action: "SELECT",
          orderIds: [this.order.id],
        },
        pushed: state,
      };
      const that = this;
      changeSendStatus(param).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          res.data.forEach((it) => {
            if (it.id == that.order.id) {
              that.order = it;
              that.$emit("update:item", it);
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    copyIt() {
      var clipboard = new Clipboard("#copyOrder");
      clipboard.on("success", (e) => {
        this.$message({
          message: "复制成功",
          type: "success",
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
  created() {},
};
</script>
<style scoped>
ul {
}
li {
  list-style-type: none;
}
.btn-item {
  list-style-type: none;
  float: left;
  margin-left: 10px;
  margin-right: 5px;
}
.send-success-icon {
  color: #07c160;
}
</style>
