<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
        <div class="biaoticss" v-if="tuanName">
          <strong
            ><i class="iconfont icon-zu132"></i>【{{
              tuanName
            }}】团购的全部订单</strong
          >
        </div>
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchForm"
          label-width="90px"
        >
          <el-form-item label="订单号" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.orderId"
              clearable
              style="width: 190px"
              placeholder="请输入订单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="收件姓名" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.buyerName"
              clearable
              style="width: 190px"
              placeholder="请输入收件人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="收件手机号" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.buyerMobile"
              clearable
              style="width: 190px"
              placeholder="请输入收件人手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="渠道(团长)" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.retailerName"
              clearable
              style="width: 190px"
              placeholder="请输入团长名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="商品名称" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.goodsName"
              clearable
              placeholder="请输入商品名称"
              style="width: 190px"
            ></el-input>
          </el-form-item>
          <el-form-item label="收单文件" style="margin-bottom: 10px">
            <el-input
              v-model="searchForm.fileName"
              clearable
              placeholder="请输入收单文件名"
              style="width: 190px"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="超时未揽收" style="margin-bottom: 10px">
            <el-select
              v-model="searchForm.pickTimeoutOption"
              clearable
              placeholder=""
              @clear="() => (searchForm.pickTimeoutOption = null)"
            >
              <el-option label="12小时" value="H12"></el-option>
              <el-option label="24小时" value="H24"></el-option>
              <el-option label="48小时" value="H48"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="超时未发货" style="margin-bottom: 10px">
            <el-select
              v-model="searchForm.sendTimeoutOption"
              clearable
              placeholder=""
              @clear="() => (searchForm.sendTimeoutOption = null)"
            >
              <el-option label="24小时" value="H24"></el-option>
              <el-option label="48小时" value="H48"></el-option>
              <el-option label="72小时" value="H72"></el-option>
            </el-select>
          </el-form-item> -->


          <el-form-item label="订单状态" style="margin-bottom: 10px">
            <el-select
              v-model="searchForm.inOrderStatus"
              clearable
              multiple
            >            
              <el-option label="待审核" value="WAIT_CONFIRM"></el-option>
              <el-option label="发货中" value="CONFIRMED"></el-option>
              <el-option label="已取消" value="CANCELED"></el-option>
              <el-option label="预售中" value="BOOKED"></el-option>
              <el-option label="已发货" value="SEND_ED"></el-option>
              <el-option label="已完成" value="COMPLETED"></el-option>
              <el-option label="已作废" value="INVALID"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="匹配商品" style="margin-bottom: 10px">
            <el-select
              v-model="searchForm.matchSku"
              clearable
              placeholder=""
              @clear="() => (searchForm.matchSku = null)"
            >
              <el-option label="已匹配" value="1"></el-option>
              <el-option label="未匹配" value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="收件城市" style="margin-bottom: 10px">
            <el-cascader
                v-model="searchForm.city"
                :options="cities"
                :props="{ expandTrigger: 'click', multiple: true }"
                collapse-tags
                clearable
                filterable
                ></el-cascader>
          </el-form-item>

          <el-form-item label="收单时间" style="margin-bottom: 10px">
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              clearable
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="searching"
            >查询</el-button
          >
          <el-button @click="chongzhi">重置</el-button>
        </el-form>
      </div>

      <div class="mainbox">
        <el-tabs v-model="activeName" @tab-click="handleTab">
          <el-tab-pane label="待审核" name="3"></el-tab-pane>
          <el-tab-pane label="发货中" name="4"></el-tab-pane>
          <el-tab-pane label="待收款" name="0"></el-tab-pane>
          <!-- <el-tab-pane label="申请取消" name="5"></el-tab-pane> -->
          <el-tab-pane label="已取消" name="6"></el-tab-pane>
          <el-tab-pane label="已发货" name="8"></el-tab-pane>
          <el-tab-pane label="预售中" name="7"></el-tab-pane>
          <el-tab-pane label="已完成" name="9"></el-tab-pane>
          <el-tab-pane label="已作废" name="10"></el-tab-pane>
          <el-tab-pane label="全部订单" name="first"></el-tab-pane>
        </el-tabs>
        <div class="caozuoqu">
          <el-button
            type="primary"
            @click="uploadOrder"
            style="margin-left: 10px"
            v-if="activeName == '3'"
            >导入订单</el-button
          >

          <el-dropdown
            @command="paramsOrders"
            style="margin-left: 10px; margin-bottom: 10px"
            v-if="activeName == '3' || activeName == 'first'"
          >
            <el-button plain type="primary">
              批量审核<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY,confirmOrder,审核"
                  >按查询</el-dropdown-item
                >
                <el-dropdown-item command="SELECT,confirmOrder,审核"
                  >按选择</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-button
            type="primary"
            plain
            @click="modifyMoney"
            style="margin-left: 10px"
            v-if="
              activeName == 0 ||
              activeName == 3 ||
              activeName == 4 ||
              activeName == 7 ||
              activeName == 8 ||
              activeName == 'first'
            "
            >修改金额</el-button
          >

          <el-dropdown
            @command="shouKuan"
            style="margin-left: 10px"
            v-if="activeName != '6' && activeName != '5' && activeName != '10'"
          >
            <el-button plain type="primary">
              预览账单<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY">按查询</el-dropdown-item>
                <el-dropdown-item command="SELECT">按选择</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            @command="batchRefund"
            style="margin-left: 10px; margin-bottom: 10px"
            v-if="activeName != '0' && activeName != '3' && activeName != '6' && activeName != '10'"
          >
            <el-button plain type="primary">
              批量退款<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY"
                  >按查询</el-dropdown-item
                >
                <el-dropdown-item command="SELECT"
                  >按选择</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            @command="paramsOrders"
            style="margin-left: 10px; margin-bottom: 10px"
            v-if="activeName != '6' && activeName != '10'"
          >
            <el-button plain type="primary">
              批量取消<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY,cancalOrder,取消"
                  >按查询</el-dropdown-item
                >
                <el-dropdown-item command="SELECT,cancalOrder,取消"
                  >按选择</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <!-- 
        <el-dropdown @command="paramsOrders" style="margin-left:10px">
          <el-button plain type="primary">
            置为已发货<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
             <el-dropdown-item command="QUERY,alreadySendOrder,置为已发货">按查询</el-dropdown-item>
             <el-dropdown-item command="SELECT,alreadySendOrder,置为已发货">按选择</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->

          <el-dropdown
            @command="pipeiSupper"
            style="margin-left: 10px"
            v-if="activeName != '6' && activeName != '10'"
          >
            <el-button plain type="primary">
              匹配商品<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY">按查询</el-dropdown-item>
                <el-dropdown-item command="SELECT">按选择</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <!-- <el-dropdown @command="paramsOrders" style="margin-left:10px">
          <el-button plain type="primary">
            批量转预售<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
             <el-dropdown-item command="QUERY,yushouOrder,转预售">按查询</el-dropdown-item>
             <el-dropdown-item command="SELECT,yushouOrder,转预售">按选择</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->

          <!-- <el-dropdown @command="paramsOrders" style="margin-left:10px">
          <el-button plain type="primary">
            申请退单<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
             <el-dropdown-item command="QUERY,applyCancelOrder,申请退单">按查询</el-dropdown-item>
             <el-dropdown-item command="SELECT,applyCancelOrder,申请退单">按选择</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->

          <!-- <el-dropdown @command="paramsOrders" style="margin-left:10px">
          <el-button plain type="primary">
            同意退单<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
             <el-dropdown-item command="QUERY,agreeCancelOrder,同意退单">按查询</el-dropdown-item>
             <el-dropdown-item command="SELECT,agreeCancelOrder,同意退单">按选择</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

       <el-dropdown @command="jujueTuidan" style="margin-left:10px">
          <el-button plain type="primary">
            拒绝退单<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
             <el-dropdown-item command="QUERY">按查询</el-dropdown-item>
             <el-dropdown-item command="SELECT">按选择</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->

          <!-- <el-dropdown @command="showAddress" style="margin-left:10px">
          <el-button plain type="primary">
            修改收货信息<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
             <el-dropdown-item command="QUERY">按查询</el-dropdown-item>
             <el-dropdown-item command="SELECT">按选择</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->

          <el-dropdown
            @command="paramsOrders"
            style="margin-left: 10px"
            v-if="activeName == '10'"
          >
            <el-button plain type="primary">
              还原订单<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY,batchRestoreOrder,还原订单"
                  >按查询</el-dropdown-item
                >
                <el-dropdown-item command="SELECT,batchRestoreOrder,还原订单"
                  >按选择</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            @command="sendDates"
            style="margin-left: 10px"
            v-if="activeName == '4'"
          >
            <el-button plain type="primary">
              批量转预售<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY">按查询</el-dropdown-item>
                <el-dropdown-item command="SELECT">按选择</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            @command="showRemark"
            style="margin-left: 10px"
            v-if="activeName != '10'"
          >
            <el-button plain type="primary">
              修改备注<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY">按查询</el-dropdown-item>
                <el-dropdown-item command="SELECT">按选择</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            @command="payOffline"
            style="margin-left: 10px"
            v-if="activeName != '3' && activeName != '10'"
          >
            <el-button plain type="primary">
              线下结算<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY,offlinePrice,线下结算"
                  >按查询</el-dropdown-item
                >
                <el-dropdown-item command="SELECT,offlinePrice,线下结算"
                  >按选择</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-dropdown
            @command="invalidateOrders"
            style="margin-left: 10px"
            v-if="activeName == '3' || activeName == 'first'"
          >
            <el-button plain type="primary">
              批量作废<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY,nullOrder,作废"
                  >按查询</el-dropdown-item
                >
                <el-dropdown-item command="SELECT,nullOrder,作废"
                  >按选择</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <!-- <el-button type="primary" plain @click="showAddress" style="margin-left:10px">修改收货信息</el-button>
        <el-button type="primary" plain @click="showRemark">修改备注</el-button> -->

          <el-dropdown @command="daochuFuns" style="margin-left: 10px">
            <el-button plain type="primary">
              导出订单<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="QUERY">按查询</el-dropdown-item>
                <el-dropdown-item command="SELECT">按选择</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-button type="primary" @click="sendGood" style="margin-left: 10px">导入快递单号</el-button>
          <!-- <el-button type="primary" @click="printBtn" style="margin-left: 10px">打印</el-button> -->
        </div>
        <el-table
          ref="table"
          :data="tableData"
          border
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
          stripe
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="40"
            header-align="center"
            align="center"
            fixed
          >
          </el-table-column>
          <el-table-column
            label="订单状态"
            width="120"
            header-align="center"
            align="center"
            fixed
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.payStatus != 1 && scope.row.status == 0"
                style="color: red"
                >{{ scope.row.statusDescription }}</span
              >
              <span v-else style="color: #07c160; font-weight: 500">{{
                scope.row.statusDescription
              }}</span>
              <span
                v-if="scope.row.payStatus != 1 && scope.row.status != 0"
                style="color: red"
                >({{ scope.row.payStatusDescription }})</span
              >
              <p v-if="scope.row.refundStatus != 0" style="color: red">
                {{ scope.row.refundStatusDescription }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="订单信息" width="350" fixed>
            <template slot-scope="scope">
              <p v-if="scope.row.dispatchErrorDescription" style="color:red">
                {{scope.row.dispatchErrorDescription}}
              </p>

              <div class="goodinfos">
                <p><span>订单号：</span>{{ scope.row.onlineOrderId }}</p>
                <p>
                  <span>商品名：</span>{{ scope.row.goodsNameForSupplier
                  }}{{
                    scope.row.specNameForSupplier
                      ? "~" + scope.row.specNameForSupplier
                      : ""
                  }}
                  × {{ scope.row.num ? scope.row.num : 0 }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="220" label="匹配商品">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.spuName">
                  {{
                    scope.row.spuName == scope.row.skuName
                      ? scope.row.skuName
                      : scope.row.spuName +
                        (scope.row.skuName == "" ? "" : "/" + scope.row.skuName)
                  }}
                  <bdo
                    >, 供货价：{{
                      scope.row.goodsSalePrice ? scope.row.goodsSalePrice : "-"
                    }}</bdo
                  >
                  <bdo class="mac" v-if="scope.row.skuCode"></bdo>
                  <br />
                  <bdo style="align: left"
                    >规格编码：{{ scope.row.skuCode }}
                  </bdo>
                </div>
                <div v-else style="color: red">无匹配商品</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="订单金额"
            width="120"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <div class="goodMoney">
                <p><span>订单：</span>￥{{ scope.row.orderPrice }}</p>
                <p><span>含运费：</span>￥{{ scope.row.deliveryPrice }}</p>
                <p v-if="scope.row.refundPrice" style="color: red">
                  <span>已退款：</span>￥{{ scope.row.refundPrice }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="付款方式"
            header-align="center"
            align="center"
            prop="payTypeDescription"
          >
          </el-table-column>
          <el-table-column width="220" label="物流(点击单号查看轨迹)">
            <template slot-scope="scope">
              <p v-if="scope.row.status == 7">
                预售时间：{{ scope.row.bookTime }}
              </p>
              <p>
                {{
                  scope.row.expressCo ? "【" + scope.row.expressCo + "】" : ""
                }}
                <span class="expressId" @click="detailDeliveryFun(scope.row)"
                  >{{ scope.row.expressId }}
                </span>
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="时间"
            width="210"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <div class="goodinfos">
                <!-- <p>
                  <span>下单时间：</span
                  >{{ scope.row.orderTime ? scope.row.orderTime : "-" }}
                </p> -->
                <p><span>收单时间：</span>{{ scope.row.sendSupplierTime ? scope.row.sendSupplierTime : '-' }}</p>
                <p>
                  <span>发货时间：</span
                  >{{ scope.row.sendTime ? scope.row.sendTime : "-" }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="retailerName"
            label="渠道(团长)"
            width="90"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column label="收件人信息" width="150">
            <template slot-scope="scope">
              {{ scope.row.consigneeName }} / {{ scope.row.consigneePhone }}
            </template>
          </el-table-column>
          <el-table-column label="备注" width="300">
            <template slot-scope="scope">
              <div>
                <p v-if="scope.row.remark">
                  <span>团员：</span>{{ scope.row.remark }}
                </p>
                <p v-if="scope.row.supplierPrivateRemark">
                  <span>私有备注：</span>{{ scope.row.supplierPrivateRemark }}
                </p>
                <p v-if="scope.row.retailerRemark">
                  <span>团长：</span>{{ scope.row.retailerRemark }}
                </p>
                <p v-if="scope.row.supplierRemark">
                  <span>供应商：</span>{{ scope.row.supplierRemark }}
                </p>
                <p class="red" v-if="scope.row.cancelStatus == 1">
                  【{{ scope.row.cancelStatusDescription }}】
                  <span
                    v-if="scope.row.cancelDetail"
                    @click="showApplyDetail(scope.row)"
                    >（查看申请详情）</span
                  >：{{ scope.row.cancelReason }}
                </p>
                <p class="red" v-if="scope.row.cancelStatus == 2">
                  【{{ scope.row.cancelStatusDescription }}】，申请取消原因：{{
                    scope.row.cancelReason
                  }}，拒绝原因：{{ scope.row.rejectCancelReason }}
                </p>
                <p v-if="scope.row.cancelStatus == 3">
                  【{{ scope.row.cancelStatusDescription }}】，申请取消原因：{{
                    scope.row.cancelReason
                  }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="expand" width="1">
            <template slot-scope="props">
              <order-buttons
                :item="props.row"
                v-on:update:item="handleUpdate"
                @refresh="()=>getTopSearch()"
                :tab="activeName"
                :role="role"
              ></order-buttons>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="150"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-popconfirm
                @confirm="agreeRefund(scope.row)"
                v-if="scope.row.cancelStatus == 1"
                confirmButtonText="确认"
                cancelButtonText="取消"
                icon="el-icon-warning"
                iconColor="red"
                title="同意退单后，如您已线上收款，系统将自动全额退款"
              >
                <el-button slot="reference" type="text" size="small"
                  >同意退单</el-button
                >
              </el-popconfirm>
              
              &nbsp;

              <el-button
                @click="disagreeRefund(scope.row)"
                type="text"
                size="small"
                v-if="scope.row.cancelStatus == 1"
                >拒绝退单</el-button
              >

              <el-button @click="detailFun(scope.row)" type="text" size="small"
                >订单详情</el-button
              >

              <el-button
                @click="toogleExpand(scope.row)"
                type="text"
                size="small"
                >{{ scope.row.less ? "收起操作" : "更多操作" }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      title="修改收货人信息"
      :visible.sync="moneyflag"
      :before-close="layerClose"
      width="600px"
    >
      <el-form
        :inline="true"
        ref="addressObj"
        :model="addressObj"
        label-width="100px"
      >
        <el-form-item label="收货人姓名" style="margin-bottom: 10px">
          <el-input
            v-model="addressObj.name"
            clearable
            style="width: 290px"
            placeholder="请输入收货人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人电话" style="margin-bottom: 10px">
          <el-input
            v-model="addressObj.phone"
            maxlength="11"
            clearable
            style="width: 290px"
            placeholder="请输入收货人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人地址">
          <el-input
            v-model="addressObj.address"
            clearable
            style="width: 290px"
            placeholder="请输入收货人地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click.native.prevent="modifyAddress"
        style="margin-left: 100px"
        >确认提交</el-button
      >
    </el-dialog>

    <el-dialog
      title="导出订单"
      :visible.sync="daochuflag"
      :before-close="layerClose"
      width="680px"
    >
      <el-form ref="moneyObj" :model="mobanObj" label-width="140px">
        <el-form-item label="选择导出模板：" style="margin-bottom: 10px">
          <el-select
            v-model="mobanObj.id"
            placeholder="请选择"
            prop="type"
            @change="checkMoban"
            style="width: 300px; margin-right: 10px"
          >
            <el-option
              v-for="item in mobanOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button type="text" size="small" @click="clickFun"
            >创建新模板</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          :loading="loadingFile"
          @click.native.prevent="piliangdaochu"
          style="margin-left: 140px; margin-top: 20px"
          >确认导出</el-button
        >
      </el-form>
    </el-dialog>

    <el-dialog
      title="匹配商品"
      :visible.sync="pipeiflag"
      :before-close="layerClose"
      width="800px"
    >
      <el-table :data="newOrderArr">
        <el-table-column
          prop="goodsNameForSupplier"
          label="商品名称"
          width="300"
        >
        </el-table-column>
        <el-table-column prop="specNameForSupplier" label="规格" width="160">
        </el-table-column>

        <el-table-column label="选择系统商品" width="300">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.skuId"
              placeholder="输入关键字搜索商品"
              filterable
              clearable
              :filter-method="(val) => skuFilter(val, scope.$index)"
              @change="(val) => changeSku(val, scope.$index)"
            >
              <el-option
                class="skuitem"
                v-for="item in scope.row.skuList"
                :key="item.id"
                :label="item.spuName + (item.name ? '/' + item.name: '')"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 10px"></div>
      <div style="text-align: right; padding-right: 30px">
        <el-button type="primary" @click.native.prevent="pipeiFuns" :loading="submitting"
          >确认提交</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="修改备注"
      :visible.sync="remarkflag"
      :before-close="layerClose"
      width="600px"
    >
      <el-form
        :inline="true"
        ref="addressObj"
        :model="addressObj"
        label-width="100px"
      >
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :row="4"
            v-model="remark"
            clearable
            placeholder="请输入"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否团长可见">
          <el-radio-group v-model="remarkPrivate">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click.native.prevent="modifyRemark"
        style="margin-left: 100px"
        >确认提交</el-button
      >
    </el-dialog>

    <el-dialog
      title="拒绝退单"
      :visible.sync="tuidanflag"
      :before-close="layerClose"
      width="600px"
    >
      <el-form :inline="true" label-width="100px">
        <el-form-item label="拒绝原因">
          <el-input
            type="textarea"
            :row="4"
            v-model="tuidanReason"
            clearable
            placeholder="请输入"
            style="width: 400px"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click.native.prevent="modifyreason"
        style="margin-left: 100px"
        >确认提交</el-button
      >
    </el-dialog>

    <el-dialog
      title="预约发货时间"
      :visible.sync="dateflag"
      :before-close="layerClose"
      width="600px"
    >
      <el-date-picker v-model="sendDateTime" type="date" placeholder="选择日期">
      </el-date-picker>
      <el-button
        type="primary"
        @click.native.prevent="saveDate"
        style="margin-left: 100px"
        >确认提交</el-button
      >
    </el-dialog>    

    <pay-order-detail ref="payOrderDetail"></pay-order-detail>

    <el-dialog
      title="导入快递单号"
      :visible.sync="kuaiDiflag"
      :before-close="handleClose"
      width="600px"
    >
      <el-form label-position="right">
        <el-upload
          ref="my-upload"
          class="upload-demo"
          drag
          action="string"
          :http-request="UploadImage"
          multiple          
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传excel文件，且不超过10M
          </div>
        </el-upload>
      </el-form>
    </el-dialog>
    <el-dialog title="订单申请取消详细信息：" :visible.sync="applyDetail">
      <div>取消数量：{{ applyCancelDetail.num }}</div>
      <div>
        是否退货：{{ applyCancelDetail.returnPackage == 1 ? "是" : "否" }}
      </div>
      <div>整单取消：{{ applyCancelDetail.cancelPart == 0 ? "是" : "否" }}</div>
      <div v-if="applyCancelDetail.expressId != ''">
        退货快递：【{{ applyCancelDetail.expressCo }}】{{
          applyCancelDetail.expressId
        }}
      </div>
    </el-dialog>

    <upload-order-dialog ref="uploadOrderDialog"></upload-order-dialog>

    <order-detail-dialog
      ref="orderDetailDialog"
      v-if="showDetail"
      @close="()=>getTopSearch()"
    ></order-detail-dialog>

    <order-delivery-dialog ref="orderDeliveryDialog"></order-delivery-dialog>

    <modify-price-dialog ref="modifyPriceDialog"></modify-price-dialog>

    <batch-refund-dialog 
    ref="batchRefundDialog"
    :visible.sync="showBatchRefundDialog" @success="()=>getTopSearch()"></batch-refund-dialog>

  </div>
</template>

<script>
import Vue from "vue";
import Clipboard from "clipboard";
import {
  getOrderSupplier,
  exportOrder,
  upDelivery,
  matchSku,
  agreeCancelOrder,
  rejectOrder,
} from "@/api/orders";
import { getGoodList, listSku } from "@/api/good";
import { listMoban } from "@/api/moban";
import * as orderFuns from "@/api/orders";
import { MessageBox } from "element-ui";
import OrderDetailDialog from "./detailInfo";
import OrderDeliveryDialog from "./delivery";
import ModifyPriceDialog from "./modifyPrice";
import UploadOrderDialog from "./upload";
import OrderButtons from "./buttons";
import PayOrderDetail from "@/views/payorder/detail"
import BatchRefundDialog from "./batchRefund"

import {
  regionData,
} from "element-china-area-data";

let moment = require("moment");
Vue.prototype.Clipboard = Clipboard;

export default {
  name: "Orderss",
  components: {
    OrderDetailDialog,
    OrderDeliveryDialog,
    ModifyPriceDialog,
    UploadOrderDialog,
    OrderButtons,
    PayOrderDetail,
    BatchRefundDialog,
  },
  data() {
    return {
      role: "supplier",
      searchForm: {
        date: [],
        action: "QUERY",
        exportTemplateId: "",
        goodsName: "",
        buyerMobile: "",
        buyerName: "",
        status: "3",
        orderId: "",
        tuanId: "",
        orderIds: [],
        fileName: "",
        role: "supplier",
        sendTimeoutOption: null,
        pickTimeoutOption: null,
        matchSku: null,
      },
      moneyOptions: [
        {
          value: 0,
          label: "统一修改",
        },
        {
          value: 1,
          label: "按商品金额",
        },
      ],
      ruleMoney: {
        type: [{ required: true, message: "请选择", trigger: "change" }],
        money: [{ required: true, message: "请输入金额", trigger: "blur" }],
        remark: [
          { required: false, message: "请输入修改金额的备注", trigger: "blur" },
        ],
      },
      shoukuanBox: {},
      shoukuanDetail: {},
      moneyObj: {
        type: 0,
        money: "",
        remark: "",
      },
      mobanObj: {
        id: window.sessionStorage.getItem("smobanid") || "",
      },
      mobanOptions: [],
      addressObj: {
        name: "",
        address: "",
        phone: "",
      },
      remark: null,
      tuanName: "",
      moneyData: [],
      allMoneyData: [],
      sendDateTime: "",
      newOrderArr: [],
      total: 0,
      page: 1,
      pageSize: 20,
      kuaiDiflag: false,
      loadingFile: false,
      orgArr: [],
      goodsku: [],
      goodindex: 0,
      tuidanReason: "",
      dateflag: false,
      tuidanflag: false,
      moneyflag: false,
      pipeiflag: false,
      remarkflag: false,
      daochuflag: false,
      ordersflag: false,
      kuanflag: false,
      activeName: "3",
      tableData: [],
      multipleSelection: [],
      showDetail: false,
      remarkPrivate: "0",
      applyDetail: false,
      applyCancelDetail: {},
      uploadflag: false,
      orderflag: false,
      orderData: [],
      searching: false,
      showBatchRefundDialog: false,
      cities: regionData,
      submitting: false,
    };
  },
  methods: {
    skuFilter(val, index) {
      const supplierOrgId = this.newOrderArr[index].supplierOrgId;
      const goodsName = this.newOrderArr[index].goodsName + this.newOrderArr[index].specName
      this.getSkus(supplierOrgId, val, index, goodsName);
    },
    getSkus(supplierOrgId, name, index, goodsName) {
      const that = this;
      this.skus = [];
      this.skuId = "";
      this.skuName = "";
      const param = {
        supplierOrgId: supplierOrgId,
        name: name,
        status: 1,
        goodsName: goodsName,
        role: this.role,
      };
      listSku(param).then((res) => {
        if (res.code === 200) {
          that.newOrderArr[index].skuList = res.data;
          that.$set(that.newOrderArr, index, that.newOrderArr[index]);
        } else {
          that.$message.error(res.message);
        }
      });
    },
    changeSku(skuId, index) {
      this.newOrderArr[index].skuId = skuId;
    },
    handleUpdate(row) {
      for (var i = 0; i < this.tableData.length; i++) {
        let item = this.tableData[i]
        if (row.id == item.id) {
          this.tableData[i] = row
        }
      }
    },
    toogleExpand(row) {
      let $table = this.$refs.table;
      for (var i = 0; i < this.tableData.length; i++) {
        let item = this.tableData[i];
        if (row.id != item.id) {
          $table.toggleRowExpansion(item, false);
          this.tableData[i].less = false;
        } else {
          this.tableData[i].less = !this.tableData[i].less;
        }
      }
      $table.toggleRowExpansion(row);
    },
    uploadOrder() {
      this.$refs.uploadOrderDialog.show(() => this.getTopSearch());
    },
    handleClose() {
      this.uploadflag = false;
      this.orderflag = false;
      this.kuaiDiflag = false;
    },
    ooClose() {
      this.orderflag = false;
    },
    detailDeliveryFun(row) {
      const that = this;
      that.$nextTick(() => {
        that.$refs.orderDeliveryDialog.init(row.id, row.expressId);
      });
    },
    handleTab(tab) {
      console.log(tab);
      this.searchForm.status = tab.name == "first" ? null : tab.name;
      this.searchForm.action = "QUERY";
      this.getTopSearch();
    },
    detailFun(row) {
      // this.$router.push({
      //   path: "/supdingdan/detail?id=" + row.id,
      // })
      const that = this;
      this.showDetail = true;
      this.$nextTick(() => {
        that.$refs.orderDetailDialog.init(row.id, that.role);
      });
    },
    createSearch() {
      this.page = 1;
      this.searchForm.action = "QUERY"
      this.getTopSearch();
    },
    invalidateOrders(e) {
      const that = this;
      this.$confirm("是否确认要作废这些订单？作废后不可还原！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.paramsOrders(e);
        })
        .catch(() => {});
    },
    showApplyDetail(row) {
      if (row.cancelDetail) {
        this.applyDetail = true;
        this.applyCancelDetail = row.cancelDetail;
      }
    },    
    agreeRefund(row) {
      const that = this;
      const param = {
        action: "SELECT",
        orderIds: [row.id],
        role: "supplier",
        pageNo: 1,
        pageSize: that.pageSize,
      };
      agreeCancelOrder(param).then((res) => {
        if (res.code === 200) {
          that.getTopSearch();
        } else {
          that.$message.error(res.message);
        }
      });
    },
    disagreeRefund(row) {
      this.multipleSelection = [{ id: row.id }]
      this.tuidanflag = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTopSearch();
    },    
    pipeiFuns() {
      var that = this;
      var ordersID = [];
      that.multipleSelection.map((item) => {
        if (item) {
          ordersID.push(item.id);
        }
      });
      that.searchForm.orderIds = ordersID;
      var objArr = {};
      objArr.queryParam = that.searchForm;
      var relations = [];
      that.newOrderArr.map((item) => {
        var objsss = {
          itemCode: item.itemCode,
          skuId: item.skuId,
        };
        relations.push(objsss);
      });
      objArr.relations = relations;
      this.submitting = true
      matchSku(objArr).then((res) => {
        this.submitting = false
        if (res.code === 200) {
          that.$message({
            message: "操作成功",
            type: "success",
          });
          that.pipeiflag = false;
          that.searchForm.action = "QUERY";
          that.getTopSearch();
        } else {
          that.$message.error(res.message);
        }
      }).catch(err => {
        this.submitting = false
      });
    },
    modifySup(e, val) {
      console.log(e);
      console.log(val);
      var that = this;
      val.skuId = e[1];
      val.spuId = e[0];
      console.log(that.newOrderArr);
    },
    batchRefund(txt) {
      var that = this;
      if (that.multipleSelection.length == 0 && txt == "SELECT") {
        that.$message({
          type: "warning",
          message: "请选择要退款的订单",
        });
        return;
      }

      let query = JSON.parse(JSON.stringify(that.searchForm))
      query.action = txt;
      query.orderIds = that.multipleSelection.map(o => o.id)
      that.$refs.batchRefundDialog.store(query)
      that.showBatchRefundDialog = true;
    },
    payOffline(e) {
      const that = this;
      this.$confirm("确认要线下结算这些订单?一经确认不可撤销！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.paramsOrders(e);
        })
        .catch(() => {});
    },
    paramsOrders(txt) {
      var that = this;
      console.log(txt);
      var valuetxt = String(txt).split(",")[1];
      var fun = orderFuns[valuetxt];
      var querys = String(txt).split(",")[0];
      var tips = String(txt).split(",")[2];
      if (that.multipleSelection.length == 0 && querys == "SELECT") {
        that.$message({
          type: "warning",
          message: "请选择要" + tips + "的订单",
        });
        return;
      }
      var ordersID = [];
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });
      var objArr = JSON.parse(JSON.stringify(that.searchForm));
      objArr.orderIds = ordersID;
      objArr.action = querys;
      fun(objArr).then((res) => {
        if (res.code === 200) {
          that.$message({
            message: "操作成功",
            type: "success",
          });
          that.searchForm.action = "QUERY";
          that.getTopSearch();
        } else {
          that.$message.error(res.message);
        }
      });
    },
    modifyMoney() {
      var that = this;
      if (that.multipleSelection.length == 0) {
        that.$message({
          type: "warning",
          message: "请选择要修改金额的订单",
        });
        return;
      }

      that.$nextTick(() => {
        that.$refs.modifyPriceDialog.init(that.multipleSelection, () =>
          that.getTopSearch()
        );
      });
    },    
    shouKuan(txt) {
      var that = this;
      var ordersID = [];
      var objs = {};
      if (that.multipleSelection.length == 0 && txt == "SELECT") {
        that.$message({
          type: "warning",
          message: "请选择要收款的订单",
        });
        return;
      }
      that.searchForm.action = txt;
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });
      that.searchForm.orderIds = ordersID;
      orderFuns.batchReceipt(that.searchForm).then((res) => {
        console.log(res);
        if (res.code === 200) {
          that.kuanflag = true;
          that.$refs.payOrderDetail.open(res.data)
        } else {
          that.$message.error(res.message);
        }
      });
    },
    showAddress(txt) {
      var that = this;
      if (that.multipleSelection.length == 0 && txt == "SELECT") {
        that.$message({
          type: "warning",
          message: "请选择要修改收货地址的订单",
        });
        return;
      } else {
        that.moneyflag = true;
        that.searchForm.action = txt;
      }
    },
    sendDates(txt) {
      var that = this;
      if (that.multipleSelection.length == 0 && txt == "SELECT") {
        that.$message({
          type: "warning",
          message: "请选择要批量转预售的订单",
        });
        return;
      } else {
        that.dateflag = true;
        that.searchForm.action = txt;
      }
    },
    jujueTuidan(txt) {
      var that = this;
      if (that.multipleSelection.length == 0 && txt == "SELECT") {
        that.$message({
          type: "warning",
          message: "请选择要拒绝的订单",
        });
        return;
      } else {
        that.tuidanflag = true;
        that.searchForm.action = txt;
      }
    },
    //根据对象某个属性去重
    uniqueFun(arr, val) {
      const res = new Map();
      return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
    },
    pipeiSupper(txt) {
      var that = this;
      let orders = that.multipleSelection;
      if (txt == "SELECT") {
        if(that.multipleSelection.length == 0) {
          that.$message({
            type: "warning",
            message: "请选择匹配商品的订单",
          });
          return;
        }
      }  else {
        orders = that.tableData
      }
      
      that.orgArr = [];
      that.newOrderArr = []
      that.searchForm.action = txt;
      that.newOrderArr = that.uniqueFun(orders, "itemCode");

      for(var i = 0; i< that.newOrderArr.length; i++) {
        this.skuFilter(null, i)
      }

      console.log(that.newOrderArr);
      that.pipeiflag = true;
    },
    saveDate() {
      var that = this;
      var objs = {};
      var ordersID = [];
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });
      if (!that.sendDateTime) {
        that.$message.error("请选择发货时间");
        return;
      }
      that.searchForm.orderIds = ordersID;
      objs.query = that.searchForm;
      objs.date = moment(that.sendDateTime).format("YYYY-MM-DD HH:mm:ss");
      orderFuns.yushouOrder(objs).then((res) => {
        if (res.code === 200) {
          that.$message({
            message: "操作成功",
            type: "success",
          });
          that.moneyflag = false;
          that.searchForm.orderIds = [];
          that.searchForm.action = "QUERY";
          that.getTopSearch();
          that.dateflag = false;
        } else {
          that.$message.error(res.message);
        }
      });
    },
    modifyAddress() {
      var that = this;
      var objs = that.addressObj;
      var ordersID = [];
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });
      that.searchForm.orderIds = ordersID;
      objs.query = that.searchForm;
      orderFuns.batchModifyConsignee(objs).then((res) => {
        if (res.code === 200) {
          that.$message({
            message: "操作成功",
            type: "success",
          });
          that.moneyflag = false;
          that.searchForm.orderIds = [];
          that.searchForm.action = "QUERY";
          that.getTopSearch();
        } else {
          that.$message.error(res.message);
        }
      });
    },
    showRemark(txt) {
      var that = this;
      if (that.multipleSelection.length == 0 && txt == "SELECT") {
        that.$message({
          type: "warning",
          message: "请选择要修改收货地址的订单",
        });
        return;
      } else {
        that.remarkflag = true;
        that.searchForm.action = txt;
      }
    },
    modifyRemark() {
      var that = this;
      var objs = {
        remark: that.remark,
        forPrivate: that.remarkPrivate,
      };
      var ordersID = [];
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });
      that.searchForm.orderIds = ordersID;
      objs.query = that.searchForm;
      orderFuns.supplierBatchModifyRemark(objs).then((res) => {
        if (res.code === 200) {
          that.$message({
            message: "操作成功",
            type: "success",
          });
          that.remarkflag = false;
          that.searchForm.orderIds = [];
          that.searchForm.action = "QUERY";
          that.getTopSearch();
        } else {
          that.$message.error(res.message);
        }
      });
    },

    modifyreason() {
      var that = this;
      var objs = {
        cancelPartOrder: 0,
        reason: that.tuidanReason,
      };
      var ordersID = [];
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });
      that.searchForm.orderIds = ordersID;
      that.searchForm.action = "SELECT";
      objs.query = that.searchForm;
      orderFuns.rejectOrder(objs).then((res) => {
        if (res.code === 200) {
          that.$message({
            message: "操作成功",
            type: "success",
          });
          that.tuidanflag = false;
          that.searchForm.action = "QUERY";
          that.getTopSearch();
          that.reason = "";
        } else {
          that.$message.error(res.message);
        }
        that.searchForm.orderIds = [];
        that.searchForm.action = "QUERY";
      });
    },

    getTopSearch() {
      var that = this;
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      datas.sendSupplierStartTime =
        that.searchForm.date.length > 0
          ? moment(that.searchForm.date[0]).format("YYYY-MM-DD 00:00:00")
          : "";
      datas.sendSupplierEndTime =
        that.searchForm.date.length > 0
          ? moment(that.searchForm.date[1]).format("YYYY-MM-DD 23:59:59")
          : "";
      datas.districtCodes = that.searchForm.city ? that.searchForm.city.map(it => it[2]) : []

      that.searching = true
      getOrderSupplier(datas).then((res) => {
        that.searching = false
        if (res.code === 200) {
          var nodataArr = res.data.rows || [];
          that.tableData = nodataArr;
          that.total = res.data.totalRows;
        } else {
          this.$message.error(res.message);
        }
      }).catch(err=>{
        that.searching = false
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    changePage(newPage) {
      var that = this;
      if (that.page === newPage) {
        return;
      }
      that.page = newPage;
      that.getTopSearch();
    },
    daochu() {
      console.log("eee");
      var that = this;
      MessageBox.alert("您是否确认要导出？", {
        confirmButtonText: "立即导出",
        type: "warning",
      }).then(() => {
        var datas = that.searchForm;
        datas.pageNo = that.page;
        datas.pageSize = that.pageSize;
        datas.startTime =
          that.searchForm.date.length > 0
            ? moment(that.searchForm.date[0]).format("YYYY-MM-DD HH:mm:ss")
            : "";
        datas.endTime =
          that.searchForm.date.length > 0
            ? moment(that.searchForm.date[1]).format("YYYY-MM-DD HH:mm:ss")
            : "";
        this.loadingFile = true;
        exportOrder(datas).then((res) => {
          that.loadingFile = false;
          if (res.code === 200) {
            let link = document.createElement("a");
            link.href = res.data;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            that.$message.error(res.message);
          }
        });
      });
    },
    daochuFuns(txt) {
      var that = this;
      if (that.multipleSelection.length == 0 && txt == "SELECT") {
        this.$message({
          type: "warning",
          message: "请选择要导出的订单",
        });
        return;
      }
      that.daochuflag = true;
      that.searchForm.action = txt;
      var datas = {};
      datas.pageNo = 1;
      datas.role = window.sessionStorage.getItem("role");
      datas.pageSize = 100;
      datas.orderByCreated = "DESC";
      listMoban(datas).then((res) => {
        if (res.code === 200) {
          that.mobanOptions = res.data;
        }
      });
    },
    clickFun() {
      var that = this;
      that.$router.push({
        path: "/sendmoban",
      });
    },
    printBtn(){
      // 获取body的全部内容并保存到一个变量中
      var bodyHtml = window.document.body.innerHTML;
      
      // 通过截取字符串的方法获取所需要打印的内容
      var printStart = 'class="mainbox">';
      var printEnd = 'class="paginations">';
      
      var printHtmlStart = bodyHtml.slice(bodyHtml.indexOf(printStart));
      var printHtml = printHtmlStart.slice(0,printHtmlStart.indexOf(printEnd));
      
      // 将截取后打印内容 替换掉 body里的内容
      window.document.body.innerHTML = printHtml;
      
      // 打印操作
      window.print();
      
      // 打印完成之后再恢复body的原始内容
      window.document.body.innerHTML = bodyHtml;
    },
    checkMoban(val) {
      window.sessionStorage.setItem("smobanid", val);
    },
    piliangdaochu(txt) {
      var that = this;
      var ordersID = [];
      if (that.mobanObj.id == "") {
        that.$message.error("请选择导出的订单模板");
        return;
      }
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });

      that.searchForm.orderIds = ordersID;
      var objArr = {};
      objArr.query = that.searchForm;
      objArr.exportTemplateId = that.mobanObj.id;
      this.loadingFile = true;
      exportOrder(objArr).then((res) => {
        that.loadingFile = false;
        if (res.code === 200) {
          let link = document.createElement("a");
          link.href = res.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          this.$message({
            type: "warning",
            message: res.message,
          });
        }
        that.daochuflag = false;
        that.moneyObj.id = "";
      }).catch(err=>{
        this.loadingFile = false
      });
    },
    copyBtn() {
      var clipboard = new Clipboard("#copybtn");
      clipboard.on("success", (e) => {
        this.$message({
          message: "复制成功",
          type: "success",
        });
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    chongzhi() {
      this.searchForm = {
        date: [],
        action: "QUERY",
        exportTemplateId: "",
        goodsName: "",
        buyerMobile: "",
        buyerName: "",
        status: this.searchForm.status,
        orderId: "",
        tuanId: "",
        orderIds: [],
        role: "supplier",
        sendTimeoutOption: null,
        pickTimeoutOption: null,
        matchSku: null,
      };
      this.tuanName = "";
      this.getTopSearch();
    },
    sendGood(txt) {
      var that = this;
      // if(that.multipleSelection.length==0  && txt == 'SELECT'){
      //   that.$message({
      //     type: 'warning',
      //     message: '请选择要修改收货地址的订单'
      //   })
      //   return
      // }else{
      that.kuaiDiflag = true;
      that.searchForm.action = "QUERY";
      // }
    },
    UploadImage(param) {
      var that = this;
      var ordersID = [];
      that.multipleSelection.map((item) => {
        ordersID.push(item.id);
      });
      that.searchForm.orderIds = ordersID;
      const formData = new FormData();
      formData.append("file", param.file);
      formData.append("role", that.role);
      formData.append("query", that.searchForm);
      upDelivery(formData).then((res) => {
        param.onSuccess();

        if (res.code === 200) {
          that.kuaiDiflag = false;
          if (res.data.code == 200) {
            that.$message({
              message: res.data.message,
              type: "success",
            });
            that.getTopSearch();
          } else {
            that.$message({
              message: res.data.message.split("\n").join(`<br/>`),
              dangerouslyUseHTMLString: true,
              type: "error",
            });
          }
        } else {
          that.$refs["my-upload"].clearFiles();
          that.$message.error(res.message);
        }
      });
    },
    layerClose() {
      this.moneyflag = false;
      this.remarkflag = false;
      this.dateflag = false;
      this.ordersflag = false;
      this.kuanflag = false;
      this.pipeiflag = false;
      this.tuidanflag = false;
      this.daochuflag = false;
      this.moneyObj.money = "";
    },
  },
  mounted() {
    console.log(this.searchForm.date[0]);
    var tuanId = this.$route.query.id;
    if (tuanId) {
      this.searchForm.tuanId = tuanId;
      this.tuanName = this.$route.query.tuanname;
    } else {
      this.searchForm.tuanId = "";
      this.tuanName = "";
    }
    this.getTopSearch();
  },
};
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #07c160;
  color: #fff;
}
.el-tabs__nav-wrap::after {
  height: 1px;
}
.el-form-item__label {
  font-size: 12px;
}
.biaoticss {
  padding-bottom: 15px;
  font-size: 15px;
}

.caozuoqu {
  float: right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
.goodinfos {
  margin-left: -10px;
}
.goodinfos p {
  color: #333;
  padding-left: 60px;
  display: block;
  text-align: left;
}
.goodinfos span {
  display: inline-block;
  text-align: left;
  color: #999;
  margin-left: -50px;
}
.goodMoney {
  text-align: left;
  font-weight: 550;
}
.goodMoney span {
  font-weight: normal;
}
.kuanleft {
  float: left;
  width: 53%;
}
.kuanCode {
  float: left;
  width: 30%;
  display: block;
}
.kuanCode p {
  text-align: center;
  font-size: 12px;
  color: #07c160;
  font-weight: bold;
  margin-top: 5px;
}
.kuanRight {
  float: right;
  width: 15%;
  display: block;
}
.kuanTT {
  clear: both;
  display: block;
  overflow: hidden;
  padding: 5px 0;
}
.kuanTitle {
  display: block;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
}
.kuanBtn {
  width: 100%;
  padding: 62px 0;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 42px;
  display: block;
  font-weight: bold;
  background-color: #07c160;
  cursor: pointer;
  border-radius: 10px;
  border: none;
}
.codesize {
  width: 200px;
  height: 200px;
  display: block;
  border: 5px solid #eee;
  margin: 0 auto;
  border-radius: 10px;
}
.kuantiao {
  display: block;
  overflow: hidden;
}
.kuantiao ol {
  float: left;
  width: 25%;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
}
.tuikuancss {
  display: block;
  margin-bottom: 20px;
}
.tuikuancss p {
  padding: 10px 0 0 0;
  display: block;
}
.red {
  color: rgb(236, 78, 15);
}
.expressId {
  cursor: pointer;
}
.upload-demo {
  display: block;
  margin: 0 auto;
  padding: 10px 0 50px;
  text-align: center;
}
.skuitem {
  min-width: 300px;
  max-width: 800px;
}
</style>