<template>
    <el-dialog title="修改订单金额" :visible.sync="ordersflag" :before-close="handleClose" width="900px">
         <el-form ref="moneyObj" :model="moneyObj" label-width="140px" :rules="ruleMoney">
          <el-form-item label="选择设置方式：" style="margin-bottom:10px">
            <el-select v-model="moneyObj.type" placeholder="请选择" prop="type" @change="onchange">
           <el-option
              v-for="item in moneyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="统一修改订单金额：" v-if="moneyObj.type==0" prop="money" style="width:400px;margin-bottom:10px">
              <el-input
              v-model="moneyObj.money" maxlength="11" clearable @change="modiyQNum"  placeholder="请输入">
              </el-input>
            </el-form-item>
            <br/>  
            <el-form-item label="统一修改运费：" v-if="moneyObj.type==0" prop="deliveryPrice" style="width:400px;margin-bottom:10px">
              <el-input v-model="moneyObj.deliveryPrice" maxlength="11" clearable @change="modiyDeliveryPrice"  placeholder="请输入运费"></el-input>
            </el-form-item>
            <br/>
            <el-form-item label="备注：" style="width:400px;margin-bottom:10px" prop="remark">
              <el-input v-model="moneyObj.remark"  type="textarea" :row="6" maxlength="1000" clearable  placeholder="请输入"></el-input>
            </el-form-item>
         </el-form>
         
    <el-table  v-if="moneyObj.type==1"
      :data="moneyData"
      style="width: 100%" >
       <el-table-column
        label="商品">
        <template slot-scope="scope">
         <p>{{scope.row.itemAttr.goodsName}}</p>         
        </template>
      </el-table-column>
      <el-table-column
        label="规格">
        <template slot-scope="scope">
         <p>{{scope.row.itemAttr.specName}}</p>
        </template>
      </el-table-column>
       <el-table-column
        label="商品价格">
        <template slot-scope="scope">
           <el-input v-model="scope.row.itemAttr.salePrice" maxlength="10" @change="modiyNum(scope.row, scope.$index)" placeholder="请输入商品价格"></el-input>
        </template>
      </el-table-column>
    </el-table>

    <el-divider content-position="left">订单明细列表：</el-divider>

    <el-table
      :data="allMoneyData"
      style="width: 100%" >
      <el-table-column
        prop="onlineOrderId"
        label="订单号"
        width="100">
      </el-table-column>
      <el-table-column
        prop="consigneeName"
        label="收件人"
        width="150">
        <template slot-scope="scope">
          {{scope.row.consigneeName}}/{{scope.row.consigneePhone}}
        </template>
      </el-table-column>
       <el-table-column
        label="商品">
        <template slot-scope="scope">
         <p>{{scope.row.itemAttr.goodsName}}</p>         
        </template>
      </el-table-column>
      <el-table-column
        label="规格">
        <template slot-scope="scope">
         <p>{{scope.row.itemAttr.specName}}</p>
        </template>
      </el-table-column>
      <el-table-column
          width=50
        label="数量">
        <template slot-scope="scope">
         <p>{{scope.row.num}}</p>
        </template>
      </el-table-column>
       <el-table-column
        label="商品价格" >
        <template slot-scope="scope">
          {{scope.row.itemAttr.salePrice}}
        </template>
      </el-table-column>
      <el-table-column
        width=100
        label="运费">
        <template slot-scope="scope">
           <el-input v-model="scope.row.deliveryPrice" 
          @change="calculate(scope.row, scope.$index)"
          placeholder="请输入运费"></el-input>
        </template>
      </el-table-column>            
      <el-table-column
        label="订单金额（含运费）" width=130>
        <template slot-scope="scope">
          <el-input v-model="scope.row.orderPrice" maxlength="10" 
          :disabled="moneyObj.type==1"
          @change="calculate(scope.row, scope.$index)" 
          placeholder="请输入订单金额"></el-input>
        </template>
      </el-table-column>
    </el-table>

         <el-button type="primary" @click.native.prevent="saveMoney" style="margin-left:140px; margin-top: 20px">确认提交</el-button>
         <el-button plain type="primary" @click.native.prevent="handleClose">取消</el-button>
      </el-dialog>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import * as orderFuns from "@/api/orders";

let moment = require('moment');
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'OrderPrice',
  data() {
    return {
      ordersflag: false,
      moneyObj: {
        type: 0,
        money: '',
        deliveryPrice: '',
        remark: ''
      },
      moneyData: [],
      allMoneyData: [],
      moneyOptions: [{
        value: 0,
        label: '订单统一修改'
      }, {
        value: 1,
        label: '按商品金额'
      }
      ],
      ruleMoney: {
        type: [{ required: true, message: '请选择', trigger: 'change' }],
        money: [{ required: true, message: '请输入金额', trigger: 'blur' }],
        remark: [{ required: false, message: '请输入修改金额的备注', trigger: 'blur' }],
      },
      successCallback: null,
    }
  },
  methods: {
    init(multipleSelection, successCallback) {      
      const multip = JSON.parse(JSON.stringify(multipleSelection))
      this.moneyObj.type = 0
      this.moneyData = multip
      this.allMoneyData = multip
      this.successCallback = successCallback
      this.ordersflag = true
    },
    onchange() {
        this.moneyObj.money = ''
        if(this.moneyObj.type == 1) {
            this.moneyData = this.uniqueFun(this.allMoneyData, 'itemCode')
        } else {
            this.moneyData = this.allMoneyData
        }
    },
    handleClose(){
      this.ordersflag = false
      this.moneyData = []
      this.allMoneyData = []
    },
    modiyNum(e,a){ // 按商品修改
      console.log(e,a)
      var that = this
      console.log(that.moneyData[a])
      that.moneyData[a].orderPrice = (e.itemAttr.salePrice) * (e.num) + Number(e.deliveryPrice)
      that.allMoneyData.map((itemss)=>{
        if(itemss.itemCode == that.moneyData[a].itemCode) {   
          itemss.itemAttr.salePrice = e.itemAttr.salePrice
          itemss.goodsSalePrice = e.itemAttr.salePrice
          itemss.orderPrice = itemss.goodsSalePrice * itemss.num + Number(itemss.deliveryPrice)
        }
      })
    },    
    calculate(e,a) {  // 定制修改
      console.log("calculate", e, a)
      this.allMoneyData[a].orderPrice = Number(e.orderPrice) || 0
      this.allMoneyData[a].deliveryPrice = Number(e.deliveryPrice) || 0
      if(this.moneyObj.type == 1) {
        this.allMoneyData[a].orderPrice = this.allMoneyData[a].goodsSalePrice * this.allMoneyData[a].num + this.allMoneyData[a].deliveryPrice
      }
    },
    modiyQNum(e){ // 统一修改
      e = Number(e)
      if(!e) return;

      var that = this
      that.moneyData.map((item)=>{
        item.orderPrice = e
      })
      that.allMoneyData.map((item)=>{
        item.orderPrice = e
      })
    },
    modiyDeliveryPrice(e) {
      e = Number(e)
      if(!e) return;

      var that = this
      that.moneyData.map((item)=>{
        item.deliveryPrice = e
      })
      that.allMoneyData.map((item)=>{
        item.deliveryPrice = e
      })
    },
    saveMoney(){
      var that = this
      var orders = [];
      var objss = {}
      
      that.allMoneyData.map((item)=>{
        objss = {
            "deliveryPrice": item.deliveryPrice,
            "goodsSalePrice": item.goodsSalePrice,
            "num": item.num,
            "id": item.id,
            "orderPrice": item.orderPrice,
            "remark": that.moneyObj.remark
        }
        orders.push(objss)
      })
      
      orderFuns.modityPrice({orders:orders}).then(res => {
        if(res.code === 200){
          that.$message({
            message: '操作成功',
            type: 'success'
          })
          
          that.handleClose()
          if(typeof that.successCallback == 'function') {
            that.successCallback()
          }
        }else{
          that.$message.error(res.message)
        }
      })
    },
   //根据对象某个属性去重
    uniqueFun(arr, val){
      const res = new Map()
      return arr.filter(item =>!res.has(item[val]) && res.set(item[val], 1))
    },
  },
  mounted(){
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.biaoticss{
  padding-bottom: 15px;
  font-size: 15px;
}
.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.caozuoqu{
  float:right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
.goodinfos{
  margin-left:-10px;
}
.goodinfos p{ color: #333; padding-left: 60px; display: block; text-align: left;}
.goodinfos span{
  display: inline-block;
  text-align: left;
  color: #999;
  margin-left:-50px;
}
.goodMoney{
  text-align: left;
  font-weight: 550;
}
.goodMoney span{
  font-weight: normal;
}
.kuanleft{
  float: left;
  width: 53%;
}
.kuanCode{
  float: left;
  width: 30%;
  display: block;
}
.kuanCode p{
  text-align: center;
  font-size: 12px;
  color: #07C160;
  font-weight: bold;
  margin-top: 5px;
}
.kuanRight{
  float: right;
  width: 15%;
  display: block;
}
.kuanTT{
  clear: both;
  display: block;
  overflow: hidden;
  padding: 5px 0;
}
.kuanTitle{
  display: block;
  font-size: 16px;color: #333; line-height:40px;
  font-weight: bold;
}
.kuanBtn{
  width: 100%;
  padding: 62px 0;
  text-align: center;
  color: #FFF;
  font-size: 16px;
  line-height:42px;
  display: block;
  font-weight: bold;
  background-color: #07C160;
  cursor: pointer;
  border-radius: 10px;
  border:none;
}
.codesize{
  width: 200px;
  height: 200px;
  display: block;
  border: 5px solid #eee;
  margin: 0 auto;
  border-radius: 10px;
}
.kuantiao{
  display: block;
  overflow: hidden;
}
.kuantiao ol{
  float: left;
  width: 25%;
   font-size: 16px;color: #333; line-height:40px;
  font-weight: bold;
}
.tuikuancss{
  display: block;
  margin-bottom: 20px;
}
.tuikuancss p{
  padding: 10px 0 0 0;display: block;
}
.red {
  color: rgb(236, 78, 15);
}
.expressId {
  cursor: pointer;
}
.upload-demo{
  display: block;
  margin: 0 auto;
  padding: 10px 0 50px;
  text-align: center;
}
</style>