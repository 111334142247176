<template>
<div>
    <el-dialog title="订单文件解析结果" :visible.sync="orderflag" :before-close="ooClose" width="1200px">

    <el-form>
      <el-form-item label="请选择订单来源渠道：" required>
        <el-select v-model="name" 
            placeholder="请选择渠道" clearable @change="onChange" filterable 
            @clear="onClear"
              :filter-method="nameFilter">
                    <el-option
                      v-for="item in groups"
                      :key="item.id"
                      :label="item.name"
                      :value="item.targetOrgId">
                </el-option>
        </el-select> 
      </el-form-item>
    </el-form>    

      <div class="orderHeight">
      <el-table
        :data="orderData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}"
        style="width: 100%">

        <el-table-column label="序号" type="index">
        </el-table-column>

        <el-table-column
          label="说明">
        <template slot-scope="scope">
          <p class="red">{{scope.row.importError}}</p>
        </template>
        </el-table-column>

        <el-table-column
          prop="onlineOrderId"
          label="订单号">
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品">
        </el-table-column>
        <el-table-column
          prop="specName"
          label="规格">
        </el-table-column>
        <el-table-column
          prop="num"
          width="50"
          label="数量">
        </el-table-column>
        <el-table-column
          prop="consigneeName"
          label="收货人" width="100px"> 
        </el-table-column>
        <el-table-column
          prop="consigneePhone"
          label="电话" width="120px"> 
        </el-table-column>
        <el-table-column
          prop="consigneeAddress"
          label="收货地址">
        </el-table-column>
      </el-table>
      </div>
        <div class="midCss" style="padding-top:20px">
          <el-button type="primary" @click="handleSubmitByDoubleConfirm" :loading="loading">确认保存</el-button>
          <el-button @click="ooClose">取 消</el-button>
        </div>
    </el-dialog>

        <el-dialog title="上传订单" :visible.sync="uploadflag" width="600px">
      <el-form ref="formAdd" label-position="right">
        <el-upload
          ref="my-upload"
          class="upload-demo"
          drag
          action="string"
          :http-request="UploadOrderExcel"
          clearFiles="removeFile"
          multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传Excel文件，且不超过1M</div>
        </el-upload>
      </el-form>
    </el-dialog>

</div>
</template>
<script>
import { importOrder, supplierBatchAdd } from "@/api/orders"
import { myRetailer } from "@/api/common.js"
import { listRetailerGroups } from "@/api/robot.js"

export default {
  name: 'Orders',
  data() {
    return {
        uploadflag: false,
        orderflag: false,
        orderData: [],
        successCallback: null,
        name: null,
        groups: [],
        target: null,
        loading: false,
    }
  },
  methods: {
      show(successCallback) {
        this.uploadflag = true
        this.successCallback = successCallback
        this.nameFilter()
      },
      searchGroups(name) {
        const param = {
            name: name,
            orderByCreated: "DESC",
            pageNo: 1,
            pageSize: 100,
        }
        const that = this
        myRetailer(param).then(res => {
            if(res.code===200) {
                that.groups = res.data.rows
            } else {
                this.$message.error(res.message)
            }
        })
      },
      onChange(event) {          
          this.target = this.groups.filter(g => g.targetOrgId == event)[0]
      },
      onClear() {
        this.nameFilter()
      },
      nameFilter(val) {
          this.name = val || ""
          this.searchGroups(val)
      },
      ooClose() {
        this.orderflag = false
        this.target = null
        this.name = null
      },
      handleClose() {
          this.uploadflag = false
      },
      UploadOrderExcel(param){
        var that = this
        const formData = new FormData()
        formData.append('file', param.file)
        formData.append("role", "supplier")
        importOrder(formData).then(res => {
            console.log('上传成功')
            if(res.code === 200){
                param.onSuccess() 
                that.orderflag = true
                that.uploadflag = false
                that.orderData = res.data
            } else {
                that.$message.error(res.message)
            }
        }).catch(response => {
            console.error('上传失败', response)
            param.onError()
            this.$message.error("上传失败，请重试")
        })
      },
      handleSubmitByDoubleConfirm() {
        if(this.target==null) {
            this.$message.error("请先选择订单来源渠道")
            return 
        }
        const msg = "您确定这些订单是【" + this.target.name + "】渠道的订单吗，一经导入不可撤销，请谨慎操作！"
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleSubmit()
        }).catch(() => {
        })
      },
      handleSubmit(){
        var that = this
        var objs = {}
        objs.orders = that.orderData
        objs.tuan = {
            id: that.uploadId
        }
        objs.retailerOrgId = that.target.targetOrgId
        objs.retailerOrgName = that.target.name
        this.loading = true
        supplierBatchAdd(objs).then(res => {
            that.loading = false
            if(res.code===200) {
                that.$message.success(res.message)
                that.successCallback && that.successCallback()
                that.ooClose()
                that.handleClose()
            } else {
                that.$message.error(res.message)
            }
        }).catch(err=>{
          this.loading = false
        })
      },
  }
}

</script>
<style scoped>
.upload-demo {
  display: block;
  margin: 0 auto;
  padding: 10px 0 50px;
  text-align: center;
}
</style>
